import React, { useEffect } from 'react';
import './galleryDash.css';
import DashHead from '../../../components/dashHead/DashHead';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { fetchGallery } from '../../../store/gallery';

function AddImg({ handleSideMenu }) {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(fetchGallery());
  }, [dispatch]);

  return (
    <div className='add_img'>
      <DashHead headTitle={"Gallery"} onClick={handleSideMenu} />
      <Outlet />
    </div>
  )
}

export default AddImg